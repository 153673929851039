import React, {Fragment} from 'react'
import { Link } from 'react-router-dom'

const teamContent = [
    {
        img: 'boss.jpg',
        desig: 'Group Chairman',
        name: 'Priyankara Sumanapalage',
        social:[
            'fab fa-facebook-f',
            'fab fa-twitter',
            'fab fa-linkedin-in',
            'fab fa-youtube',
        ],
        dataAos:'fade-up',
        dataDelay: '50',
    },
    {
        img: 'Thilina Wijerathne Vice President Operations.png',
        desig: 'Vice President Operations',
        name: 'Thilina Wijeratne',
        social:[
            'fab fa-facebook-f',
            'fab fa-twitter',
            'fab fa-linkedin-in',
            'fab fa-youtube',
        ],
        dataAos:'fade-up',
        dataDelay: '50',
    }
   
]

const TeamTwo = () => {
    return (
        <Fragment>
            <div className="row gx-12 gx-xxl-5 justify-content-center">
                {teamContent.map((val, i)=>(
                    <div key={i} className="col-lg-12 col-md-6" data-aos={val.dataAos} data-aos-delay={val.dataDelay}>
                        <div className="team-style-two text-center mb-45">
                            <div className="team">
                                <a><img style={{height:'300px', width:'225px'}} src={`assets/img/team/${val.img}`} alt="img"/></a>
                                
                            </div>
                            <div className="team-content">
                            <h3 className="sect-title-two">
                                    <a>{val.name}</a>
                                </h3>
                                <p>{val.desig}</p>
                              
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </Fragment>
    )
}

export default TeamTwo