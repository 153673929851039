import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

const BlockStyleOne = () => {
    return (
        <Fragment>
            <div className="block-style-one pt-10 mb-45 text-black">
                <h1 className="mb-30">Aviha Group of Companies</h1>
                <span style={{fontSize:'20px'}}>Aviha Group is one of Sri Lanka's leading holding companies, encompassing four world-class brands: Colombo Bartender & Barista School, Colombo Caregiver & Health Science School, Jobdaddy.lk and DOCMART Pharmaceuticals.
                    We aim to deliver the highest ROI by connecting opportunities and people across the world through education and recruitment with a highly experienced and passionate team of professionals across multiple industries under one brand.
                </span>

                {/* <ul className="block-box list-none justify-content-center">
                    <div className="row">
                        <div className='col-1'></div>
                        <div className="col-5">
                            <li className="box-item">
                                <a>
                                    <span className="img-bg">
                                        <img src="assets/img/icon/icon-1a.svg" alt="icon" />
                                    </span>
                                    <span className="box-content">Professional Training Programs</span>
                                </a>
                            </li>

                            <li className="box-item">
                                <a>
                                    <span className="img-bg">
                                        <img src="assets/img/icon/icon-1a.svg" alt="icon" />
                                    </span>
                                    <span className="box-content">Industry-Leading Certifications</span>
                                </a>
                            </li>
                        </div>
                        <div className="col-5">
                            <li className="box-item">
                                <a>
                                    <span className="img-bg">
                                        <img src="assets/img/icon/icon-1a.svg" alt="icon" />
                                    </span>
                                    <span className="box-content">International Recruitment Services</span>
                                </a>
                            </li>

                            <li className="box-item">
                                <a>
                                    <span className="img-bg">
                                        <img src="assets/img/icon/icon-1a.svg" alt="icon" />
                                    </span>
                                    <span className="box-content">Comprehensive Career Guidance</span>
                                </a>
                            </li>
                        </div>
                    </div>
                </ul> */}

            </div>
        </Fragment>
    )
}

export default BlockStyleOne