import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'




//components
import ThemeBannerOne from '../../components/theme-banner/ThemeBannerOne'
import BlockStyleOne from '../../components/block/BlockStyleOne'
import FeatureSliderOne from '../../components/feature-slider/FeatureSliderOne'
import CounterOne from '../../components/counter/CounterOne'
import NewsletterOne from '../../components/call-to-action/NewsletterOne'
import FooterOne from '../../components/footer/FooterOne'
import Sectors from '../../components/sectors/sectors'
import Clients from '../../components/clients/Clients'
import AllSectors from '../../components/sectors/AllSectors'






const ItSolution = () => {
    return (
        <Fragment>

            <div className="main-page-wrapper">
                {/* Helmet start */}
                <Helmet>
                    <title>Aviha Group of Companies</title>
                </Helmet>
                {/* Helmet end */}


                {/*ThemeBannerOne start*/}
                <ThemeBannerOne />
                {/*ThemeBannerOne end*/}



                {/*counter-one end*/}
                <CounterOne />
                {/*counter-one start*/}



                {/*about-techy start*/}
                <section className="about-techy position-relative py-5"  
                style={{
                    background: '#fcb21d'
                  }}
                >
                    <div className="container-fluid">
                        <div className="row justify-content-center align-items-center text-center">
                            <div className="col-lg-8" data-aos="fade-left" data-aos-delay={100}>
                                {/* BlockStyleOne start */}
                                <BlockStyleOne />
                                {/* BlockStyleOne end */}
                            </div>
                        </div>
                    </div>
                </section>
                {/*about-techy end*/}



                {/* <section className="brand-area pt-50 pb-30 pt-lg-20 pb-lg-25" style={{ backgroundColor: '#fff' }}>
                        <div className="row gx-4 gx-xxl-5">
                            <BrandOne />
                        </div>
                </section>
 */}



                <section className="our-services pt-80 pb-105 pt-lg-45 pb-lg-15">
                    <div className="container">
                        <div className="row gx-4 gx-xxl-5 justify-content-lg-start justify-content-center">
                       
                        </div>
                        <div className="row gx-4 gx-xxl-5">
                            <AllSectors />
                        </div>
                    </div>
                </section>

                
                {/*brand-area start*/}
              


                {/*feature-work start*/}
                {/* <section className="feature-work-area pt-90 pb-95 pt-lg-50 pb-lg-60">
                    <div className="container">
                        <div className="row gx-4 gx-xxl-5 align-items-center">
                            <div className="col-xl-5 col-lg-6 col-md-8">
                                <div className="section-title text-center text-md-start mb-55">
                                    <h3 className="sect-title mb-25" data-aos="fade-up">Our Companies</h3>
                                    <p data-aos="fade-up" data-aos-delay={100}>Dramatically supply transparent
                                        backward deliverables before caward comp internal or "organic" sources.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container feature-custom-container">
                        <div className="feature-slider-one slick-nav">
                            <BrandSlider />
                        </div>
                    </div>
                </section> */}
                {/*feature-work end*/}

                  
                <section className="brand-area pt-3 pt-lg-3 pb-3" style={{ backgroundColor: '#fff' }}>
                        <div className="row gx-4 gx-xxl-5">
                            {/* BrandOne start */}
                            <Clients />
                            {/* BrandOne end */}
                        </div>
                </section>

           


              

                {/*feature-work start*/}
                <section className="feature-work-area pt-90 pb-95 pt-lg-40 pb-lg-60">
                    <div className="container">
                        <div className="row gx-4 gx-xxl-5 align-items-center">
                            <div className="col-xl-5 col-lg-6 col-md-8">
                                <div className="section-title text-center text-md-start mb-55">
                                    <h3 className="sect-title mb-25" data-aos="fade-up">Events</h3>
                                    <p data-aos="fade-up" data-aos-delay={100}>Discover our rich history and commitment to fostering a dynamic and engaging work environment.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container feature-custom-container">
                        <div className="feature-slider-one slick-nav">
                            <FeatureSliderOne />
                        </div>
                    </div>
                </section>
                {/*feature-work end*/}

            
                {/*techy-newsletter start*/}
                <NewsletterOne />

                {/*techy-newsletter end*/}

                {/*footer-area start*/}
                <FooterOne />
                {/*footer-area end*/}

            </div>
        </Fragment>
    )
}

export default ItSolution