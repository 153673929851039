import React, {Fragment} from 'react'
import CommonCounter from './CommonCounter'


const CounterOneContent = [
    {
        dataAos:"fade-up",
        dataDelay:"",
        num:120,
        text:"Number of Employees",
        mark:"+"
    },
    {
        dataAos:"fade-up",
        dataDelay:"200",
        text:"Years of Expertise",
        num:15,
        mark:"+"
    },
    {
        dataAos:"fade-up",
        dataDelay:"300",
        num:400,
        text:"Stakeholders",
        mark:"+"
    },
    {
        dataAos:"fade-up",
        dataDelay:"400",
        num:70,
        text:"Service Receivers",
        mark:"k"
    },
]



const CounterOne = () => {
    return (
        <Fragment>
            <section className="counter-area pt-25 pt-lg-10 pt-lg-15 pb-lg-10">
                <div className="container custom-contianer-one">
                    <div className="row gx-4 gx-xxl-5">
                        {CounterOneContent.map((val, i)=>(
                            <div key={i} className="col-lg-3 col-md-6 col-sm-6" data-aos="fade-up">
                                <div className="counter-style-one mb-30">
                                    <h3><span className="counter"><CommonCounter value={val.num} /></span>{val.mark}</h3>
                                    <h4>{val.text}</h4>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default CounterOne