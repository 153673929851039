import React, {Fragment} from 'react'
// import { Link } from 'react-router-dom'

const SocialIcon = [
    {
        icon: 'fab fa-facebook-f',
        routerPath:'https://www.facebook.com/p/Aviha-Group-100070033276546/',
    },
    {
        icon: 'fab fa-tiktok',
        routerPath:'https://www.tiktok.com/@aviha.group?_t=8qwEfDowTNU&_r=1',
    },
    {
        icon: 'fab fa-linkedin-in',
        routerPath:'https://www.linkedin.com/company/aviha-group',
    },
    {
        icon: 'fab fa-instagram',
        routerPath:'https://www.instagram.com/avihagroup?igsh=MWQ3eXkyNWRmYXUwOQ==',
    },
]

const SocialOne = () => {
    return (
        <Fragment>
            <div className="footer__social mb-30">
                {SocialIcon.map((val, i)=>(
                    <a key={i} href={val.routerPath} target="_blank" rel="noreferrer">
                        <i className={val.icon}/>
                    </a>
                ))}
            </div>
        </Fragment>
    )
}

export default SocialOne