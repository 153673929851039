import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

// Components
import PageTitle from '../../../components/page-title/PageTitle';
import FooterThree from '../../../components/footer/FooterThree';
import ThemeMenuOne from '../../../components/header/ThemeMenuOne';

const EventDetails = () => {
    const [event, setEvent] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        // Fetch events data from the JSON file
        fetch('/data/events.json')
            .then(response => response.json())
            .then(data => {
                // Find the event by id
                const selectedEvent = data.find(event => event.id === parseInt(id));
                setEvent(selectedEvent);
            })
            .catch(error => console.error("Error fetching event data:", error));
    }, [id]);

    if (!event) {
        return <p>Loading event details...</p>;
    }

    return (
        <Fragment>
            <div className="main-page-wrapper">

                <Helmet>
                    <title>Aviha Group of Companies</title>
                </Helmet>
                <header
                    className="w-100 text-center"
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(0, 0, 0, 0))',
                        width: '100%',
                        height: '120px',
                        zIndex: 9999
                    }}
                >
                    {/*ThemeMenuOne start*/}
                    <ThemeMenuOne />
                    {/*ThemeMenuOne end*/}
                </header>

                <PageTitle title="Event Details" />

                <section className="blog-single-area pt-150 pb-80 pt-lg-60">
                    <div className="container">
                        <div className="row gx-4 gx-xxl-5">
                            <div className="col-lg-12 mb-60">
                                <div className="blog-style-five mb-25">
                                    <div className="blog-content">
                                        <h3>
                                            <Link className="blog-title" to={`/event/${event.id}`}>{event.title}</Link>
                                        </h3>
                                        <div className="blog-meta mb-35">
                                            <span><i className="bi bi-clock" />{event.date}</span>
                                        </div>
                                    </div>
                                    <div className="blog-thumb mb-40">
                                        {event.image.endsWith('.mp4') ? (
                                            <div className="service-video">
                                                <div className="service-single-video mt-30 mb-30">
                                                    <video
                                                        className="w-100"
                                                        src={event.image}
                                                        autoPlay
                                                        muted
                                                        controls // Optional: Add controls if you want users to pause/play
                                                        loop // Optional: Loop the video
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            <img className="w-100" src={event.image} alt="event-img" />
                                        )}
                                    </div>

                                    {event.description.map((paragraph, idx) => (
                                        <p key={idx} className="text-white mb-25">{paragraph}</p>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <FooterThree />
            </div>
        </Fragment>
    );
};

export default EventDetails;
