import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'



//componets
import TeamTwoA from '../../../components/team/TeamTwoA'
import FooterThree from '../../../components/footer/FooterThree'
import ThemeMenuOne from '../../../components/header/ThemeMenuOne'


const Team = () => {
    return (
        <Fragment>
            <div className="main-page-wrapper">
                {/* Helmet start */}
                <Helmet>
                    <title>Aviha Group of Companies</title>
                </Helmet>
                {/* Helmet end */}

                {/* ThemeMenuThree */}
                <header
                    className="w-100 text-center"
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(0, 0, 0, 0))',
                        width: '100%',
                        height: '120px',
                        zIndex: 9999
                    }}
                >
                    {/*ThemeMenuOne start*/}
                    <ThemeMenuOne />
                    {/*ThemeMenuOne end*/}
                </header>
                {/* ThemeMenuThree */}

              
                {/*techy-team start*/}
                <section className="techy-team team-sec-two mt-0 pt-100 pb-145 pt-lg-55 pb-lg-60">
                    <div className="container">
                       
                        {/* TeamTwoA start*/}
                        <TeamTwoA />
                      
                    </div>
                </section>
                {/*techy-team end*/}

              

                <FooterThree />

            </div>

        </Fragment>
    )
}

export default Team