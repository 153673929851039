import React, { Fragment } from 'react';

const BlogFourContent = [
  {
    id: 1,
    img: 'sectors.jpg',
    desc: `Starting as a small consultancy in hospitality training, Aviha Group has evolved to operate in sectors including vocational education, healthcare, manpower solutions, fashion, and agriculture. Our flagship educational ventures include the Colombo Bartender & Barista School, Colombo Caregiver & Health Science School, and specialized institutions for nursing, beauty, wellness, and technical skills. These ventures, alongside our investments in retail, online intermediation, and sustainable agriculture, reflect our commitment to meeting the needs of our communities.`,
    dataAos: 'fade-up',
    dataDelay: '50',
  },
  {
    id: 2,
    img: 'empower.jpg',
    desc: `At Aviha, we believe in empowering both our employees and the communities we serve. Through our robust corporate social responsibility initiatives, we support education for underserved children, environmental projects, and vocational training in orphanages. Our team-centered approach fosters a positive work culture with benefits such as flexible working hours, medical coverage, and career development opportunities.`,
    dataAos: 'fade-up',
    dataDelay: '50',
  },
];

const History = () => {
  return (
    <Fragment>
      {BlogFourContent.map((val, i) => (
        <div
          key={i}
          className="blog-style-two style-four mb-lg-25 mb-75"
          data-aos={val.dataAos}
          data-aos-delay={val.dataDelay}
        >
          <div className="row gx-4 gx-xxl-5">
            {/* Image section */}
            <div className={`col-lg-6 ${i === 1 ? 'order-lg-2' : ''}`}>
              <div className="blog-thumb">
                <img className="w-100" src={`assets/img/about/${val.img}`} alt="img" />
              </div>
            </div>
            
            {/* Description section */}
            <div className={`col-lg-6 ${i === 1 ? 'order-lg-1' : ''}`}>
              <div className="blog-content-right text-left">
                <p>{val.desc}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Fragment>
  );
};

export default History;
