import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'


//componets
import PageTitle from '../../../components/page-title/PageTitle'
import FooterThree from '../../../components/footer/FooterThree'
import ThemeMenuOne from '../../../components/header/ThemeMenuOne'
import Events from '../../../components/events/Events'

const AllEvents = () => {

    return (
        <Fragment>
            <div className="main-page-wrapper">
                {/* Helmet start */}
                <Helmet>
                    <title>Aviha Group of Companies</title>
                </Helmet>
                {/* Helmet end */}

                {/* ThemeMenuThree */}
                <ThemeMenuOne />
                {/* ThemeMenuThree */}

                {/* PageTitle */}
                <PageTitle title="Events" />
                {/* PageTitle */}

                {/*blog-classic-area start*/}
                <section className="blog-classic-area pt-150 pt-lg-60">
                    <div className="container">
                    <div className="row gx-4 gx-xxl-5 justify-content-lg-start justify-content-center">
                       
                       </div>
                        <div className="row gx-4 gx-xxl-5">
                            <div className="col-lg-12">
                                {/* BlogStyleFour start */}
                                <Events />
                                {/* BlogStyleFour end */}
                            </div>
                           
                        </div>
                        
                    </div>
                </section>
                {/*blog-classic-area end*/}

             

                {/* FooterThree start */}
                <FooterThree />
                {/* FooterThree end */}

            </div>

        </Fragment>
    )
}

export default AllEvents