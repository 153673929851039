import React, { Fragment } from 'react'

const BlogFourContent = [

    {
        id: 1,
        img: 'vision.jpg',
        title: 'Vision',
        desc: `To ease people's lives by providing simple yet complete and comprehensive solutions.`,
        dataAos: 'fade-right',
        dataDelay: '50',
    },
    {
        id: 2,  
        img: 'mission.jpg',
        title: 'Mission',
        desc: `At AVIHA we believe in simple yet complete, comprehensive, and user-friendly solutions in designing and developing customer solutions. Thus, we always promote the power of research knowledge and technology which has always assisted us to deviate from convention.`,
        dataAos: 'fade-left',
        dataDelay: '50',
    }

]

const CompanyDetails = () => {
    return (
        <Fragment>
        {BlogFourContent.map((val, i) => (
            <div key={i} className="blog-style-two style-four mb-lg-45 mb-175" data-aos={val.dataAos} data-aos-delay={val.dataDelay}>
                <div className="row gx-4 gx-xxl-5">
                    <div className="col-lg-6">
                        <div className="blog-thumb">
                            <img className="w-100" src={`assets/img/about/${val.img}`} alt="img" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="blog-content-left">
                           
                            <h3>
                                <a className="blog-title">{val.title}</a>
                            </h3>
                            
                            <p>{val.desc}</p>
                        </div>
                    </div>
                </div>
            </div>
        ))}
    </Fragment>
    )
}

export default CompanyDetails