import React, {Fragment} from 'react'
import { Link } from 'react-router-dom'
import CopyRightOne from './CopyRightOne';

const ContactContent = [
    {
      link: 'info@avihagroup.com'  
    },
    {
      link: '(+94) 11 458 8301'  
    },
    {
      link: '57/8, Dr. Lester James Peries Mawatha, Colombo 05, Sri Lanka.'  
    },

  
];

const FooterThree = () => {
    return (
        <Fragment>
            <footer className="footer-area theme-footer-three pt-145 pt-lg-100 pt-sm-100" style={{backgroundColor:'#02164d'}}>
                <img className="footer-shape shape-1b" src="/assets/img/shape/star-3b.svg" alt="shape"/>
                <img className="footer-shape shape-2b" src="/assets/img/shape/star-4b.svg" alt="shape"/>
                <img className="footer-shape shape-3b" src="/assets/img/shape/star-6b.svg" alt="shape"/>
                <img className="footer-shape shape-4b" src="/assets/img/shape/star-5b.svg" alt="shape"/>
                <img className="footer-shape shape-5b" src="/assets/img/shape/star-5b.svg" alt="shape"/>
                <img
                    className="fot-shape-one"
                    src="/assets/img/shape/line-round-7b.svg"
                    alt="footer shape"/>
                <div className="container">
                    <div className="row gx-4 gx-xxl-5 mb-10">
                        <div className="col-xxl-3 col-md-4" data-aos="fade-up">
                            <div className="footer__widget mb-40">
                                <Link className="logo-title" to="/">Aviha</Link>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-md-4" data-aos="fade-up">
                            <div className="footer__widget mb-40">
                                <h4 className="widget-title">Contact</h4>
                                <ul className="fot-list">
                                   {ContactContent.map((val,i)=>(
                                     <li key={i}>
                                        <a href={val.routerPath}>{val.link}</a>
                                    </li>
                                   ))}
                                </ul>
                            </div>
                        </div>
                        
                            <div className="col-xxl-6 col-md-4" data-aos="fade-up" data-aos-delay={100}>
                            <div className="footer__widget mb-40">
                            <div
                                className="footer__widget mb-30"
                                data-aos="fade-right"
                                data-aos-delay={200}>
                                <p className="pe-xl-3 foter-para">
                                    We aim to deliver the highest ROI by connecting opportunities and people across the world through education and recruitment with a highly experienced and passionate team of professionals across multiple industries under one brand.
                                </p>
                              
                            </div>
                            </div>
                        </div>
                        {/* <div className="col-xxl-3 col-md-4" data-aos="fade-up" data-aos-delay={100}>
                            <div className="footer__widget mb-40">
                                <h4 className="widget-title">Our Instagram</h4>
                                <ul className="insta-feed list-none">
                                    {InstaFeed.map((val, i)=>(
                                        <li key={i}>
                                            <Link to={val.routerPath}><img src={`assets/img/instagram/${val.img}.jpg`} alt="img"/></Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div> */}
                        {/* <div
                            className="col-xxl-3 col-xl-4 col-md-6"
                            data-aos="fade-up"
                            data-aos-delay={200}>
                            <div className="footer__widget mb-40">
                                <h4 className="widget-title">Subscribe</h4>
                                <p className="fs-17 text-white mb-0 pb-20">Phosfluorescentl aggregate bleed channels rather global araa.
                                </p>
                               
                                <SubscribeFormTwo />
                               
                            </div>
                        </div> */}
                    </div>
                </div>
              
            </footer>
            {/*footer-area end*/}
  {/* CopyRightThree */}
  <CopyRightOne />
                {/* CopyRightThree */}
        </Fragment>
    )
}

export default FooterThree