import React, { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const ContactForm = () => {
    const [successMessage, setSuccessMessage] = useState(""); // State for success message

    // Validation schema
    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Name is Required"),
        email: Yup.string().required("Email is Required").email("Entered value does not match email format"),
        comment: Yup.string().required("Please, leave us a comment.")
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    // Hook form functions
    const { register, handleSubmit, formState: { errors }, reset } = useForm(formOptions);

    const onSubmit = (data) => {
        const formData = new FormData();
        formData.append("name", data.name);
        formData.append("email", data.email);
        formData.append("comment", data.comment);

        fetch(
            "https://script.google.com/macros/s/AKfycbyx2MzkkgD9FyrjNuHvajzcBgnyrVbIerLq4_2sFFm2IbGx79dL-Y0oseHUj5-aGIwFZw/exec",
            {
                method: "POST",
                body: formData,
                mode: "no-cors",
            }
        )
        .then(() => {
            setSuccessMessage("Your message was successfully sent!"); // Set success message
            reset(); // Reset the form fields
        })
        .catch((error) => {
            console.error("Error:", error);
        });
    };

    return (
        <Fragment>
            {successMessage && (
                <div className="alert alert-success">
                    {successMessage}
                </div>
            )}
            <form className="widget-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="row gx-4 gx-xxl-5 px-0">
                    <div className="col-md-6 mb-10">
                        <div className="form-input-box">
                            <label className="fs-16 text-white">Name</label>
                            <input
                                type="text"
                                name="name"
                                placeholder="Enter Name"
                                {...register("name")}
                                className={`${errors.name ? "is-invalid" : ""}`}
                            />
                            {errors.name && (
                                <div className="invalid-feedback">{errors.name.message}</div>
                            )}
                        </div>
                    </div>
                    <div className="col-md-6 mb-10">
                        <div className="form-input-box">
                            <label className="fs-16 text-white">Email Id</label>
                            <input
                                type="email"
                                name="email"
                                placeholder="Email"
                                {...register("email")}
                                className={`${errors.email ? "is-invalid" : ""}`}
                            />
                            {errors.email && (
                                <div className="invalid-feedback">{errors.email.message}</div>
                            )}
                        </div>
                    </div>
                    <div className="col-md-12 mb-20">
                        <div className="form-input-box">
                            <label className="fs-16 text-white">Comment</label> 
                            <textarea
                                name="comment"
                                placeholder="Write Comment" 
                                {...register("comment")}
                                className={`${errors.comment ? "is-invalid" : ""}`} 
                            />
                            {errors.comment && (
                                <div className="invalid-feedback">{errors.comment.message}</div>
                            )}
                        </div>
                    </div>
                    <div className="col-12">
                        <button type="submit" className="theme_btn">Submit Now</button>
                    </div>
                </div>
            </form>
        </Fragment>
    );
};

export default ContactForm;
