import React, {Fragment} from 'react'
import { Link } from 'react-router-dom'

const featureContent = [
    {
        arrow: 'bi bi-arrow-up-right',
        frontIcon: 'training-w', 
        backIcon: 'training-b',
        title: 'Professional Training Programs',
        desc: 'Equip yourself with top-notch skills in various fields through our expertly designed training programs.',
        pview: '324 Projects',
        dataAos: 'fade-up',
        dataDelay: ''
    },
    {
        arrow: 'bi bi-arrow-up-right',
        frontIcon: 'presentation-w',
        backIcon: 'presentation-b',
        title: 'Free Vocational Training Quota for Orphanages',
        desc: 'Empowering orphans with vocational skills for a brighter, independent future through free training.',
        pview: '501 Projects',
        dataAos: 'fade-up',
        dataDelay: '200'
    },
  
    {
        arrow: 'bi bi-arrow-up-right',
        frontIcon: 'certificate-w',
        backIcon: 'certificate-b',
        title: 'Industry-Leading Certifications',
        desc: 'Gain industry-recognized certifications that boost your professional credentials and career prospects.',
        pview: '231 Projects',
        dataAos: 'fade-up',
        dataDelay: '100'
    },
    {
        arrow: 'bi bi-arrow-up-right',
        frontIcon: 'career-w', 
        backIcon: 'career-b',
        title: 'Comprehensive Career Guidance',
        desc: 'Receive expert guidance and support to navigate your career path and achieve your goals.',
        pview: '121 Projects',
        dataAos: 'fade-up',
        dataDelay: '150'
    },
    {
        arrow: 'bi bi-arrow-up-right',
        frontIcon: 'book-w',
        backIcon: 'book-b',
        title: 'School Materials Donation',
        desc: 'Supporting underprivileged schools with essential materials to foster a better learning environment.',
        pview: '224 Projects',
        dataAos: 'fade-up',
        dataDelay: '250'
    },
    {
        arrow: 'bi bi-arrow-up-right',
        frontIcon: 'recruitment-w',
        backIcon: 'recruitment-b',
        title: 'International Recruitment Services',
        desc: 'Connect with global job opportunities through our dedicated international recruitment services.',
        pview: '420 Projects',
        dataAos: 'fade-up',
        dataDelay: '50'
    }
   

];

const FeatureOne = () => {
    return (
        <Fragment>
            {featureContent.map((val, i) => (
                <div key={i} className="col-lg-4 col-md-6" data-aos={val.dataAos} data-aos-delay={val.dataDelay}>
                    <div className="feature-style-one mb-45">
                        <a className="top-arrow">
                            <i className={val.arrow}/>
                        </a>
                        <img className="front-icon" src={`assets/img/icon/${val.frontIcon}.png`} alt="icon"/>
                        <img className="back-icon" src={`assets/img/icon/${val.backIcon}.png`} alt="icon"/>
                        <h2>
                            <Link>{val.title}</Link>
                        </h2>
                        <p>{val.desc}</p>
                        {/* <Link to="/project-grid">324 Projects</Link> */}
                    </div>
                </div>
            ))}
        </Fragment>
    )
}

export default FeatureOne