import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

const featureContent = [
    {
        arrow: 'bi bi-arrow-up-right',
        frontIcon: 'onboarding-w',
        backIcon: 'onboarding-b',
        title: 'Manpower',
        desc: 'Connecting talent with opportunities across various industries.',
        dataAos: 'fade-up',
        dataDelay: '200',
        url: '/sector-categories/manpower',
        img: 'manpower.jpg',
    },
    {
        arrow: 'bi bi-arrow-up-right',
        frontIcon: 'classroom-w',
        backIcon: 'classroom-b',
        title: 'Education',
        desc: 'Empowering individuals through skill-building programs for a brighter future.',
        dataAos: 'fade-up',
        dataDelay: '',
        url: '/sector-categories/education',
        img: 'edu.jpg',
    },

    {
        arrow: 'bi bi-arrow-up-right',
        frontIcon: 'healthcare-w',
        backIcon: 'healthcare-b',
        title: 'Healthcare',
        desc: 'Providing support and services to improve community health and well-being.',
        dataAos: 'fade-up',
        dataDelay: '100',
        url: '/sector-categories/healthcare',
        img: 'pharmacy.jpg',
    }
];

const AllSectors = () => {
    return (
        <Fragment>
          {featureContent.map((val, i) => (
    <div key={i} className="col-lg-4 col-md-6" data-aos={val.dataAos} data-aos-delay={val.dataDelay}>
        <div className="feature-style-one mb-45">
            <Link to={`${val.url}`}>
                <a className="top-arrow">
                    <i className={val.arrow} />
                </a>
            </Link>
            <div className="d-flex">
                <img className="front-icon me-3" src={`assets/img/icon/${val.frontIcon}.png`} alt="icon" />
                <img className="back-icon me-3" src={`assets/img/icon/${val.backIcon}.png`} alt="icon" />
                <h4 className="text-center mb-0 feature-title">
                    <Link to={`${val.url}`}>{val.title}</Link>
                </h4>
            </div>
            <Link to={`${val.url}`}>
                <img className='w-100 mt-5 mb-3 banner-img' src={`assets/img/sectors/${val.img}`} alt="img" />
            </Link>
            <p className='text-center'>{val.desc}</p>
            <div className='text-center'>
                <Link to={`${val.url}`}>Read More</Link>
            </div>
        </div>
    </div>
))}

        </Fragment>
    )
}

export default AllSectors