import React, {Fragment} from 'react'
import { Link } from 'react-router-dom'



const SideBar = (props) => {

    const {onClick, value} = props;
    
    return (
        <Fragment>

            {/* slide-bar start */}
            <aside className={value?"slide-bar show":"slide-bar"}>
                <div className="close-mobile-menu">
                    <a href="#" onClick={onClick}>
                        <i className="fas fa-times"/>
                    </a>
                </div>
                {/* offset-sidebar start */}
                <div className="offset-sidebar">
                    <div className="offset-widget offset-logo mb-30">
                        <Link className="nav-link" to="/"><img src="assets/img/logo/aviha.png" style={{width:'120px', marginTop:'-30px'}} alt="Header-logo"/></Link>
                    </div>
                    <div className="offset-widget mb-40">
                        <div className="info-widget">
                            <h4 className="offset-title mb-20">About Us</h4>
                            <p className="mb-30">
                            Aviha Group is one of Sri Lanka's leading holding companies, encompassing four world-class brands: Colombo Bartender & Barista School, Colombo Caregiver and Health Science School, Jobdaddy.lk and DOCMART Pharmaceuticals.
                            </p>
                        </div>
                    </div>
                    <div className="offset-widget mb-30 pr-10">
                        <div className="info-widget info-widget2">
                            <h4 className="offset-title mb-20">Contact Info</h4>
                            <p>
                                <i className="fal fa-address-book"/>
                                57/8, Dr. Lester James Peries Mawatha, Colombo 05, Sri Lanka.</p>
                            <p>
                                <i className="fal fa-phone"/>
                                (+94) 11 458 8301
                            </p>
                            <p>
                                <i className="fal fa-envelope-open"/>
                                info@avihagroup.com
                            </p>
                        </div>
                    </div>
                    {/* <div className="login-btn text-center">
                        <Link className="theme_btn w-100" to="/login">Login</Link>
                    </div> */}
                </div>
                {/* offset-sidebar end */}
            </aside>
            {/* slide-bar end */}
        </Fragment>
    )
}

export default SideBar