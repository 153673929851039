import React, {Fragment} from 'react'
import { Link } from 'react-router-dom';
import CopyRightOne from './CopyRightOne';


const FooterNav = [
   {
    name:'About Us',
    routerPath:'/about',
   },
   {
    name:'Contact Us',
    routerPath:'/contact',
   },
   {
    name:'Services',
    routerPath:'/services',
   },
   {
    name:'Events',
    routerPath:'/all-events',
   }

]

const FooterOne = () => {

    return (
        <Fragment>
            <footer className="footer-area theme-footer-three pt-140 pt-lg-95" style={{backgroundColor:'#02164d'}}>
                <img
                    className="fot-shape-one"
                    src="assets/img/shape/line-round-4a.svg"
                    alt="footer shape"/>
                <img className="footer-shape shape-1b" src="/assets/img/shape/star-3b.svg" alt="shape"/>
                {/* <img className="footer-shape shape-2b" src="/assets/img/shape/star-4b.svg" alt="shape"/> */}
                <img className="footer-shape shape-3b" src="/assets/img/shape/star-6b.svg" alt="shape"/>
                <img className="footer-shape shape-4b" src="/assets/img/shape/star-5b.svg" alt="shape"/>
                <img className="footer-shape shape-5b" src="/assets/img/shape/star-5b.svg" alt="shape"/>
                <div className="container">
                    <div className="row gx-4 gx-xxl-5 mb-1">
                        <div className="col-lg-4 col-md-8">
                            <div
                                className="footer__widget mb-30"
                                data-aos="fade-right"
                                data-aos-delay={200}>
                                <p className="pe-xl-3 foter-para">
                                    We aim to deliver the highest ROI by connecting opportunities and people across the world through education and recruitment with a highly experienced and passionate team of professionals across multiple industries under one brand.
                                </p>
                               
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4" data-aos="fade-up" data-aos-delay={300}>
                            <div className="footer__widget d-flex justify-content-md-center mb-30">
                                <ul className="fot-list">
                                    {FooterNav.map((val, i)=>(
                                        <li key={i}>
                                            <Link to={val.routerPath}>{val.name}</Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4" data-aos="fade-left" data-aos-delay={200}>
                            <div className="footer__widget mb-30 ps-xl-5">
                                <h4 className="text-white mb-40">info@avihagroup.com</h4>
                                <address>57/8, Dr. Lester James Peries Mawatha, Colombo 05, Sri Lanka.</address>
                                <address>(+94) 11 458 8301</address>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
         <CopyRightOne />
        </Fragment>
    )
}

export default FooterOne