import React, { useState, useEffect } from 'react';
import Snowfall from 'react-snowfall';

const SnowEffect = () => {
  const [snowflakeCount, setSnowflakeCount] = useState(200); // Default to 200 snowflakes

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) { // Mobile view breakpoint
        setSnowflakeCount(20); // Set snowflakes count to 100 for mobile
      } else {
        setSnowflakeCount(50); // Set snowflakes count to 200 for larger screens
      }
    };

    handleResize(); // Call initially to set the correct snowflake count
    window.addEventListener('resize', handleResize); // Listen for resize events

    return () => {
      window.removeEventListener('resize', handleResize); // Cleanup event listener
    };
  }, []);

  return (
    <div style={{ position: 'relative', zIndex: 9999 }}>
      <Snowfall
        color="#fff" // Color of snowflakes
        snowflakeCount={snowflakeCount} // Dynamic snowflake count based on screen size
        style={{
          position: 'fixed', // Make it fixed on the screen
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          pointerEvents: 'none', // To ensure it doesn't interfere with other UI elements
          zIndex: 9999, // Make sure snowflakes are on top of everything
        }}
      />
      {/* Your other content */}
    </div>
  );
};

export default SnowEffect;
