import React from 'react'
import { Link } from 'react-router-dom'
import MetisMenu from '@metismenu/react';
import 'metismenujs/dist/metismenujs.css';

const ProjectMenu = [
  {
    name: 'Education',
    routerPath: '/sector-categories/education' 
  },
  {
      name: 'Manpower',
      routerPath: '/sector-categories/manpower' 
  },
  {
      name: 'Healthcare',
      routerPath: '/sector-categories/healthcare' 
  },

];


const MobileMenu = (props) => {

  const { onClick, active } = props;




  return (
    // <div className={active?"mobile-menu-wrapper show d-lg-none":"mobile-menu-wrapper d-lg-none"}>
    <div className={`mobile-menu-wrapper ${active} d-lg-none`}>
      <div className="mobile-logo mb-5">
      <Link className="nav-link" to="/"><img src="/assets/img/logo/Aviha-3D.png" style={{width:'170px', marginTop:'-30px'}} alt="Header-logo"/></Link>
      </div>
      <div className="close-menu" onClick={onClick}>
        <i className="bi bi-x-lg"></i>
      </div>
      <div className="side-mobile-menu">
        <MetisMenu>

          <li onClick={onClick}>
            <Link to="/coming-soon">partners</Link>
          </li>
          <li onClick={onClick}>
            <Link to="/about">About</Link>
          </li>

          <li className="has-dropdown">
            <a href="#">Sectors</a>
            <ul className="sub-menu">
              {ProjectMenu.map((val, i) => (
                <li onClick={onClick} key={i}>
                  <Link to={val.routerPath}>{val.name}</Link>
                </li>
              ))}
            </ul>
          </li>
          <li onClick={onClick}>
            <Link to="/all-events">Events</Link>
          </li>
          <li onClick={onClick}>
            <Link to="/services">Services</Link>
          </li>

          <li onClick={onClick}>
            <Link to="/contact">Contact Us</Link>
          </li>
        </MetisMenu>
      </div>
     
    </div>
  )
}

export default MobileMenu