import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import ThemeMainMenu from './ThemeMainMenu';
import SearchModal from '../search-modal/SearchModal';
import SideBar from '../sidebar/SideBar';
import MobileMenu from './MobileMenu';

const ThemeMenuOne = () => {
    const [sticky, setSticky] = useState(false);
    const [modalIsopen, setOpen] = useState(false);
    const [showSidebar, setShowSidebar] = useState(false);
    const [menu, setMenu] = useState("");

    const openModal = () => {
        setOpen(true);
    };
    const closeModal = () => {
        setOpen(false);
    };

    const openSidebar = () => {
        setShowSidebar(true);
    };
    const closeSidebar = () => {
        setShowSidebar(false);
    };
    const showMobilemenu = () => {
        setMenu('show');
    };
    const hideMobilemenu = () => {
        setMenu();
    };

    const stickyMenu = () => {
        if (window.scrollY >= 60) {
            setSticky(true);
        } else {
            setSticky(false);
        }
    };
    window.addEventListener('scroll', stickyMenu);

    return (
        <Fragment>
            <SearchModal isOpen={modalIsopen} onClick={closeModal} />
            <SideBar onClick={closeSidebar} value={showSidebar} />
            {/* header-area start */}
            <header className="theme-main-menu py-lg-4"> 
                <div className={sticky ? "main-header-area sticky-menu" : "main-header-area"}>
                    <div className="container custom-container-nav d-flex justify-content-center align-items-center">
                        {/* Mobile View: Hamburger and Logo aligned left and right */}
                        <div className="d-flex w-100 d-lg-none justify-content-between align-items-center">
                            <div className="hamburger-menu">
                                <a className="round-menu" href="#" onClick={showMobilemenu}>
                                    <i className="bi bi-list" />
                                </a>
                            </div>
                            <Link className="nav-link" to="/">
                                <img src="/assets/img/logo/Aviha-3D.png" style={{ width: '170px' }} alt="Header-logo" />
                            </Link>
                        </div>
                        {/* Desktop View: Centered Menu */}
                        <nav className="navbar navbar-expand-lg d-none d-lg-flex justify-content-center">
                            <div className="container-fluid">
                                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ThemeMainMenu />
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
                {/* /.theme-main-menu */}
                <MobileMenu onClick={hideMobilemenu} active={menu} />
            </header>
            {/* header-area end */}
        </Fragment>
    );
}

export default ThemeMenuOne;
