import React, { Fragment } from 'react';

const BlogFourContent = [
    {
        id: 1,
        sector: 'Education',
        logo: 'cbbs.png',
        img: 'cbbs-2.jpg',
        title: 'Colombo Bartender & Barista School',
        desc: `Welcome to Sri Lanka's leading Bartender & Barista School. We pride ourselves on 6+ years of training & mentoring bartenders, baristas, and beyond.`,
        dataAos: 'fade-left',
        dataDelay: '50',
        btnText: 'Explore More',
        url: 'https://barbaristaschool.com/'
    },
    {
        id: 2,
        sector: 'Education',
        logo: 'cchss.png',
        img: 'cchss-3.jpg',
        title: 'Colombo Caregiver & Health Science School',
        desc: `Colombo Caregiver & Health Science School is Sri Lanka’s premier institution for caregiver, nursing, phlebotomy, and pharmacy technician training. Accredited by the TVEC and the only franchise of the American Caregiver Association (ACA) in the country, it exclusively offers the National Caregiver Certificate Course (NCCC), USA. Additionally, the school provides various healthcare courses in nursing, phlebotomy, and pharmacy technician training.`,
        dataAos: 'fade-right',
        dataDelay: '100',
        btnText: 'Explore More',
        url: 'https://www.ccsedu.lk/'
    },
    {
        id: 3,
        sector: 'Manpower',
        logo: 'jobdaddy.png',
        img: 'manpower.jpg',
        title: 'Jobdaddy.lk',
        desc: `Empowering job seekers to find their dream careers with boundless opportunities.`,
        dataAos: 'fade-left',
        dataDelay: '50',
        btnText: 'Explore More',
        url: 'https://jobdaddy.lk/'
    },
    {
        id: 4,
        sector: 'Healthcare',
        logo: 'docmart.jpg',
        img: 'docmart.jpg',
        title: 'Docmart Pharmaceuticals (Pvt) Ltd.',
        desc: `Your trusted partner for high-quality pharmaceuticals. Providing reliable healthcare solutions to communities, one product at a time.`,
        dataAos: 'fade-left',
        dataDelay: '50',
        btnText: 'Explore More',
        url: 'https://docmart.lk/'
    },
    
    
];

const CategorySector = ({ sector }) => {
    // Filter content based on the sector prop
    const filteredContent = BlogFourContent.filter(
        (item) => item.sector.toLowerCase() === sector.toLowerCase()
    );

    return (
        <Fragment>
            {filteredContent.map((val) => (
                <div key={val.id} className="blog-style-two style-four mb-lg-45 mb-75" data-aos={val.dataAos} data-aos-delay={val.dataDelay}>
                    <div className="row gx-4 gx-xxl-5">
                        <div className="col-lg-6 mb-5">
                            <div className="blog-thumb text-center">
                                <img className="w-100" src={`/assets/img/brand-slider/${val.img}`} alt="img" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="blog-content-ccs">
                               
                                {/* <img style={{height:'100px', margin:'20px'}} src={`/assets/img/brand/${val.logo}`} alt="img" /> */}
                                <div className="d-flex align-items-center">
    <img 
        className="img-fluid me-3" 
        style={{ height: '100px' }} 
        src={`/assets/img/brand/${val.logo}`} 
        alt="img" 
    />
</div>

                                <h3>
                                    <a target='_blank' className="blog-title" href={`${val.url}`}>{val.title}</a>
                                </h3>
                                <p>{val.desc}</p>
                                <a className="more-btn" target='_blank' href={`${val.url}`}>{val.btnText}</a>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </Fragment>
    );
};

export default CategorySector;
