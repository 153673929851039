import React, { Fragment } from 'react'
import Marquee from 'react-fast-marquee';

const BrandLogo = [
    { img: 't.jpeg', width: 100, height: 100 },
    { img: 'ois.png', width: 130, height: 100 },
    { img: 'rococo.png', width: 130, height: 90 },
    { img: 'american.png', width: 180, height: 100 },
    { img: 'hemas.png', width: 140, height: 100 },
    { img: 'khc.png', width: 150, height: 120 },
    { img: 'kiu.png', width: 160, height: 90 },
    { img: 'lkh.png', width: 100, height: 100 },
    { img: 'manchester.png', width: 120, height: 120 },
    { img: 'sitrek.png', width: 150, height: 60 },
    { img: 'cic.jpeg', width: 120, height: 120 },
    { img: 'durdans.jpeg', width: 120, height: 100 },
    { img: 'fab.jpeg', width: 150, height: 100 },
    { img: 'morison.jpeg', width: 100, height: 90 },
    { img: 'roots.jpeg', width: 140, height: 100 },
    { img: 'singer.png', width: 160, height: 100 },
    { img: 'nawaloka.png', width: 120, height: 100 },
    { img: 'kapruka.png', width: 120, height: 100 },
    { img: 'metro.png', width: 120, height: 100 },
   
];


const Clients = () => {
    return (
        <Fragment>
            <div className="marquee-container">
                <Marquee
                    gradient={false}
                    speed={50}
                    pauseOnHover={true}
                >
                    {BrandLogo.map((val, i) => (
                        <div key={i} style={{ padding: '0 50px' }}>
                            <img
                                src={`assets/img/ourclients/${val.img}`}
                                alt="brand"
                                style={{ width: val.width, height: val.height }}
                            />
                        </div>
                    ))}
                </Marquee>
            </div>

        </Fragment>
    );
}

export default Clients;
