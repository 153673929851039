import React, { Fragment, useEffect, useState } from 'react';
import ThemeMenuOne from '../header/ThemeMenuOne';

const ThemeBannerOne = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, 500); // Delay before fading in (adjust as needed)

        return () => clearTimeout(timer); // Cleanup on unmount
    }, []);

    return (
        <Fragment>
            {/* theme-banner-one start */}
            <div
                style={{
                    backgroundImage: 'url(assets/img/bg/banner-first.jpg)', // Update this path
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: '100vh',
                    transition: 'opacity 1s ease-in-out',
                    opacity: isVisible ? 1 : 0, // Control opacity
                    position: 'relative',
                }}
                className="d-flex flex-column align-items-center justify-content-start"
            >
                {/* Header Section */}
                <header
                    className="w-100 text-center"
                  
                >
                    {/*ThemeMenuOne start*/}
                    <ThemeMenuOne />
                    {/*ThemeMenuOne end*/}
                </header>


                <div
                    style={{
                        position: 'absolute',
                        top: '85%',
                        transform: 'translateY(-50%)',
                        textAlign: 'center',

                    }}
                >
                    <h1 className="text-white responsive-text">
                        Empowering lives with simple,<br />
                        comprehensive solutions.
                    </h1>
                </div>
            </div>
            {/* theme-banner-one end */}
        </Fragment>
    );
}

export default ThemeBannerOne;
