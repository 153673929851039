import React, { Fragment } from 'react'
import {Link} from 'react-router-dom'

 
const ProjectMenu = [
  {
    name: 'Education',
    routerPath: '/sector-categories/education' 
  },
  {
      name: 'Manpower',
      routerPath: '/sector-categories/manpower' 
  },
  {
      name: 'Healthcare',
      routerPath: '/sector-categories/healthcare' 
  },

];



const ThemeMainMenu = () => {
  return (
    <Fragment>
        <ul className="navbar-nav main-navbar mb-2 mb-lg-0 ps-1 text-center pt-1">
            
          
            <li className="nav-item">
              <Link className="nav-link" to="/coming-soon">Partners</Link>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" id="navbarDropdown">
                Sectors
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                {ProjectMenu.map((val, i)=>(
                  <li key={i}><Link className="dropdown-item" to={val.routerPath}>{val.name}</Link></li>
                ))}
              </ul>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/about">About</Link>
            </li>
          
            <li className="nav-item">
           
                <Link className="nav-link" to="/"><img src="/assets/img/logo/Aviha-3D.png" style={{width:'170px', marginTop:'-50px'}} alt="Header-logo"/></Link>
             
            </li>

              <li className="nav-item">
              <Link className="nav-link" to="/all-events">Events</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/services">Services</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contact">Contact</Link>
            </li>
        </ul>
    </Fragment>
  )
}

export default ThemeMainMenu