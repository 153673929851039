import React from 'react'
import { Routes, Route } from 'react-router-dom'
import ScrollTopBehavior from '../components/ScrollTopBehavior'

//Home Pages
import ItSolution from '../views/home-pages/ItSolution'

//inner-pages
//about-page
import About from '../views/inner-pages/about/About'

//services-pages
import Services from '../views/inner-pages/services/Services'

//team-pages
import Team from '../views/inner-pages/team/Team'

//pages
import Faq from '../views/inner-pages/pages/Faq'
import Error from '../views/inner-pages/pages/Error'
import ComingSoon from '../views/inner-pages/pages/ComingSoon'

//conatct-page
import Contact from '../views/inner-pages/pages/Contact'

//error-404
import NotFound from '../views/NotFound'
import AllEvents from '../views/inner-pages/events/AllEvents'
import EventDetails from '../views/inner-pages/events/EventDetails'
import Sectors from '../views/inner-pages/sectors/Sectors'


const AppRouter = () => {
    return (
        <>
            <ScrollTopBehavior />
            <Routes>
                <Route path="/" element={<ItSolution />} />
              

                <Route path="/about" element={<About />} />

                <Route path="/services" element={<Services />} />

                <Route path="/all-events" element={<AllEvents />} />
                <Route path="/event/:id" element={<EventDetails/>} />

                <Route path="/sector-categories/:sector" element={<Sectors />} />

                <Route path="/team" element={<Team />} />

                <Route path="/faq" element={<Faq />} />
                <Route path="/error" element={<Error />} />
                <Route path="/coming-soon" element={<ComingSoon />} />

                <Route path="/contact" element={<Contact />} />

                <Route path="*" element={<NotFound />} />
            </Routes>
        </>
    )
}

export default AppRouter