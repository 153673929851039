import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'






//componets
import ThemeMenuOne from '../../../components/header/ThemeMenuOne'
import PageTitle from '../../../components/page-title/PageTitle'
import NewsletterTwo from '../../../components/call-to-action/NewsletterTwo'
import FooterThree from '../../../components/footer/FooterThree'
import ContactForm from '../../../components/form/ContactForm'
import SocialFive from '../../../components/social/SocialFive'





const Contact = () => {

    return (
        <Fragment>
            <div className="main-page-wrapper">
                {/* Helmet start */}
                <Helmet>
                    <title>Aviha Group of Companies</title>
                </Helmet>
                {/* Helmet end */}

                {/* Header Section */}
                <header
                    className="w-100 text-center"
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        background: '#050d37', /* Solid colors for opaque gradient */
                        width: '100%',
                        height: '120px', 
                        zIndex: 9999,
                        
                    }}
                >
                    {/*ThemeMenuOne start*/}
                    <ThemeMenuOne/>
                    {/*ThemeMenuOne end*/}
                </header>

                {/* PageTitle */}
                <PageTitle title="Contact" />
                {/* PageTitle */}

                {/*contact-section start*/}
                <div className="contact-section pt-145 pb-100 pt-lg-60 pb-lg-15">
                    <div className="container">
                        <div className="row gx-4 gx-xxl-5">
                            <div className="col-lg-5">
                                <div className="contact-info-wrapper me-xxl-5 pe-xxl-5 mb-45">
                                    <h2 className="sect-title mb-5">Connect with us</h2>
                                    <div className="single-info-box mb-50">
                                        <div className="icon">
                                            <i className="bi bi-telephone" />
                                        </div>
                                        <span className="mt-10">(+94) 11 458 8301</span>
                                    </div>
                                    <div className="single-info-box mb-50">
                                        <div className="icon">
                                            <i className="bi bi-geo-alt" />
                                        </div>
                                        <span className="mb-10">57/8, Dr. Lester James Peries Mawatha, Colombo 05, </span>
                                        <span>Sri Lanka.</span>
                                    </div>
                                    <div className="single-info-box mb-50">
                                        <div className="icon">
                                            <i className="bi bi-envelope" />
                                        </div>
                                        <span className="mt-10">info@avihagroup.com</span>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="contact-content-wrapper mb-45">
                                    <div className="row gx-4 gx-xxl-5 px-0 align-items-center mb-25">
                                        <div className="col-xxl-8 col-md-7">
                                           
                                        </div>
                                        <div className="col-xxl-4 col-md-5">
                                            {/* SocialFive start */}
                                            <SocialFive />
                                            {/* SocialFive end */}
                                        </div>
                                    </div>
                                    <div className="contact-map pb-40 mb-xxl-4">
                                        <iframe src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Aviha%20Group%20of%20Companies,57/8,%20Dickman%E2%80%99s%20Road,%20Colombo%2005,%20Sri%20Lanka.+(Aviha%20Group%20of%20Companies)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" />
                                    </div>
                                    <div className="contact-form-two">
                                        <h3 className="post-title mb-50">Send us a message</h3>
                                        {/* ContactForm start */}
                                        <ContactForm />
                                        {/* ContactForm end */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*contact-section end*/}


                {/* FooterThree start */}
                <FooterThree />
                {/* FooterThree end */}

            </div>

        </Fragment>
    )
}

export default Contact