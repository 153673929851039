import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'

//components
import PageTitle from '../../../components/page-title/PageTitle'
import FooterThree from '../../../components/footer/FooterThree'
import ThemeMenuOne from '../../../components/header/ThemeMenuOne'
import FeatureOne from '../../../components/feature/FeatureOne'


const Services = () => {
    return (
        <Fragment>
            <div className="main-page-wrapper">
                {/* Helmet start */}
                <Helmet>
                    <title>Aviha Group of Companies</title>
                </Helmet>
                {/* Helmet end */}

                {/* ThemeMenuThree */}
                <ThemeMenuOne />
                {/* ThemeMenuThree */}

                {/* PageTitle */}
                <PageTitle title="Services" />
                {/* PageTitle */}

                {/*our-services start*/}
                <section className="our-services pt-80 pb-105 pt-lg-45 pb-lg-15">
                    <div className="container">
                        <div className="row gx-4 gx-xxl-5 justify-content-lg-start justify-content-center">
                       
                        </div>
                        <div className="row gx-4 gx-xxl-5">
                            <FeatureOne />
                        </div>
                    </div>
                </section>
                {/*our-services end*/}


              

                {/* FooterThree start */}
                <FooterThree />
                {/* FooterThree end */}

            </div>

        </Fragment>
    )
}

export default Services