import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

// Components
import PageTitle from '../../../components/page-title/PageTitle';
import FooterThree from '../../../components/footer/FooterThree';
import ThemeMenuOne from '../../../components/header/ThemeMenuOne';
import CategorySector from '../../../components/sectors/CategorySector';

const Sectors = () => {
    const { sector } = useParams(); // Capture the URL parameter

    const sectorNames = {
        education: 'Education',
        manpower: 'Manpower',
        healthcare: 'Healthcare'
    };

    // Get the full sector name if it exists, or default to 'Sectors'
    const sectorTitle = sector ? (sectorNames[sector.toLowerCase()] || 'Sectors') : 'Sectors';


    return (
        <Fragment>
            <div className="main-page-wrapper">
                {/* Helmet start */}
                <Helmet>
                    <title>Aviha Group of Companies</title>
                </Helmet>
                {/* Helmet end */}

                {/* ThemeMenuOne */}
                <ThemeMenuOne />
                {/* ThemeMenuOne */}

                {/* PageTitle */}
                <PageTitle title={sectorTitle} />
                {/* PageTitle */}

                {/* blog-classic-area start */}
                <section className="blog-classic-area pt-150 pt-lg-60">
                    <div className="container">
                        <div className="row gx-4 gx-xxl-5 justify-content-lg-start justify-content-center">
                            {/* Other content can go here */}
                        </div>
                        <div className="row gx-4 gx-xxl-5">
                            <div className="col-lg-12">
                                {/* Pass sector as a prop to CategorySector */}
                                <CategorySector sector={sector} />
                            </div>
                        </div>
                    </div>
                </section>
                {/* blog-classic-area end */}

                {/* FooterThree start */}
                <FooterThree />
                {/* FooterThree end */}
            </div>
        </Fragment>
    );
};

export default Sectors;
