import React, { Fragment, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

import ThemeMenuOne from '../../../components/header/ThemeMenuOne'
import PageTitle from '../../../components/page-title/PageTitle'
import TeamTwo from '../../../components/team/TeamTwo'
import NewsletterOne from '../../../components/call-to-action/NewsletterOne'
import FooterThree from '../../../components/footer/FooterThree'
import CompanyDetails from '../../../components/about/CompanyDetails'
import History from '../../../components/about/History'
import TeamTwoA from '../../../components/team/TeamTwoA'


const About = () => {

    return (
        <Fragment>
            <div className="main-page-wrapper">
                {/* Helmet start */}
                <Helmet>
                    <title>Aviha Group of Companies</title>
                </Helmet>
                {/* Helmet end */}

                {/* Header Section */}
                <header
                    className="w-100 text-center"
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        background: '#050d37',
                        width: '100%',
                        height: '120px',
                        zIndex: 9999
                    }}
                >
                    {/*ThemeMenuOne start*/}
                    <ThemeMenuOne />
                    {/*ThemeMenuOne end*/}
                </header>
                {/* PageTitle */}
                <PageTitle title="About Us" />
                {/* PageTitle */}



                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-lg-8" data-aos="fade-up" data-aos-delay={100}>
                            <div className="block-style-one pt-10 mb-45 text-white text-center">
                                <h1 className="mb-50 text-white text-center">Aviha Group of Companies</h1>
                                <span style={{ fontSize: '20px', textAlign: 'center', display: 'block' }}>
                                    Founded in Colombo, Sri Lanka, the Aviha Group of Companies is a multi-dimensional conglomerate dedicated to providing innovative, sustainable solutions across diverse industries. Over the past decade, Aviha has transformed into a trusted and influential presence in each of its target markets. Guided by a vision to make life simpler and more efficient, Aviha combines the power of research, technology, and creativity to deliver solutions that balance both scientific and artistic excellence.
                                </span>

                                
                    
                        <div className="row gx-4 gx-xxl-5 pt-100 pt-lg-60">
                            <div className="col-lg-12">
                                {/* BlogStyleFour start */}
                                <History />
                                {/* BlogStyleFour end */}
                            </div>

                        </div>

                 

                                {/* <br />
                                <span style={{ fontSize: '20px', textAlign: 'center', display: 'block' }}>
                                    Starting as a small consultancy in hospitality training, Aviha Group has evolved to operate in sectors including vocational education, healthcare, manpower solutions, fashion, and agriculture. Our flagship educational ventures include the Colombo Bartender & Barista School, Colombo Caregiver & Health Science School, and specialized institutions for nursing, beauty, wellness, and technical skills. These ventures, alongside our investments in retail, online intermediation, and sustainable agriculture, reflect our commitment to meeting the needs of our communities.
                                </span> */}
                                {/* <br />
                                <span style={{ fontSize: '20px', textAlign: 'center', display: 'block' }}>
                                    At Aviha, we believe in empowering both our employees and the communities we serve. Through our robust corporate social responsibility initiatives, we support education for underserved children, environmental projects, and vocational training in orphanages. Our team-centered approach fosters a positive work culture with benefits such as flexible working hours, medical coverage, and career development opportunities.
                                </span> */}
                              

                                <span style={{ fontSize: '20px', textAlign: 'center', display: 'block' }}>

                                    Our journey is one of continuous growth, driven by our dedication to innovation, quality, and the people we serve. Welcome to Aviha Group, where we strive to make a meaningful difference in the fabric of our region and beyond.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                {/*blog-classic-area start*/}
                {/* <section className="blog-classic-area pt-100 pt-lg-60"> */}
                    <div className="container pt-100 pt-lg-60">
                        <div className="row gx-4 gx-xxl-5 justify-content-lg-start justify-content-center">

                        </div>
                        <div className="row gx-4 gx-xxl-5">
                            <div className="col-lg-12">
                                {/* BlogStyleFour start */}
                                <CompanyDetails />
                                {/* BlogStyleFour end */}
                            </div>

                        </div>

                    </div>
                {/* </section> */}
                {/*blog-classic-area end*/}





                {/*techy-team start*/}
                <section className="gradient-bg techy-team team-sec-two mt-0 pt-95  pb-90 pt-lg-55 pb-lg-60">
                    <img className="team-shape shape-1b" src="assets/img/shape/star-3b.svg" alt="shape" />
                    <img className="team-shape shape-2b" src="assets/img/shape/star-4b.svg" alt="shape" />
                    <img className="team-shape shape-3b" src="assets/img/shape/star-6b.svg" alt="shape" />
                    <img className="team-shape shape-4b" src="assets/img/shape/star-5b.svg" alt="shape" />
                    <img className="team-shape shape-5b" src="assets/img/shape/star-5b.svg" alt="shape" />
                    <img className="team-shape shape-6b" src="assets/img/shape/line-round-6b.svg" alt="shape" />
                    <div className="container">
                  
                        <TeamTwoA />
                      
                    </div>
                </section>
                {/*techy-team end*/}



                {/*techy-newsletter start*/}
                <NewsletterOne />
                {/*techy-newsletter end*/}

                {/*FooterThree start*/}
                <FooterThree />
                {/*FooterThree end*/}

            </div>

        </Fragment>
    )
}

export default About